@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css';


.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 1.5rem;
}

.gap-3 {
    gap: 2rem;
}